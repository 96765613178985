import React from 'react';
import myPhoto from '../assets/images/profile-photo.jpg';

export default function AboutMe(props) {
  const {
    darkMode,
    titleColor,
    isTitle,
    isFontFamily,
    fontStyle,
    isBackground,
    colorBackground,
    isDescription,
    descriptionColor,
  } = props;

  return (
    <div
      className='about-container shadow page-title'
      id='about'
      style={{ backgroundColor: isBackground && colorBackground }}
    >
      <div className='grid-2-about'>
        <div className='flex-about'>
          <h1
            className='title-about gray'
            style={{
              color: isTitle ? titleColor : darkMode ? '#f2C080' : '#777',
              fontFamily: isFontFamily && fontStyle,
            }}
          >
            ABOUT
          </h1>
        </div>
        <div className='description-container grid-2-description'>
          <div className='description-sub-container'>
            <p
              className='description'
              style={{ color: isDescription && descriptionColor }}
            >
              I'm a <strong> software developer</strong> with 3 years of
              experience in creating web applications. My journey in tech began
              with a fascination for computer science and the dynamic world of
              apps, which inspired me to pursue a career in software
              development. Over the years, I've worked on various projects that
              have enhanced my skills and deepend my understanding of software
              development.
            </p>
          </div>

          {darkMode && (
            <div className='hidden-image-container'>
              <img className='hidden-image' src={myPhoto} alt='profile' />
            </div>
          )}

          {!darkMode ? (
            <div className='img-container'>
              <img className='prof-pic' src={myPhoto} alt='profile' />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
