import React from 'react'
import { motion } from "framer-motion"

export default function Header(props) {
    const { 
        darkMode,
        isBackground, 
        colorBackground, 
        isFontFamily, 
        fontStyle,
        isDescription,
        descriptionColor } = props

    const line1 = "Hi, I'm Gervie."
    const line2 = "Nice to meet you.."

    const sentence = {
        hidden: {opacity: 1},
        visible: {
            opacity: 1,
            transition: {
                delay: 0.5,
                staggerChildren: 0.08,              
            },
        },
    }

    const letter = {
        hidden: {opacity: 0, y: 50},
        visible: {
            opacity: 1,
            y: 0,
        },
    }

    return (
        <div className="header-container" id="home" style={{ background: isBackground && colorBackground}}>
            <div className="tagline-container">
            <motion.h1
                className="name"
                style={{color: isDescription? descriptionColor: darkMode? "#fff" : "#777",
                        fontFamily: isFontFamily? fontStyle: 'Georgia'}}
                variants={sentence}
                initial="hidden"
                animate="visible"
                >
                {line1.split("").map((char, index)=> {
                    return (
                        <motion.span key={char + "-" + index} variants={letter}>
                            {char} 
                        </motion.span>
                    )
                })}
                <br />
                {line2.split("").map((char, index)=> {
                    return (
                        <motion.span key={char + "-" + index} variants={letter} className="text">
                            {char}
                        </motion.span>
                    )
                })}
                </motion.h1>
            </div>
        </div>
    )  
}
