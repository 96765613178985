import React from 'react'
import axios from 'axios'
import { URL } from '../config.js'
import mail from '../assets/images/mail.png'
import location from '../assets/images/location.png'
import linkedin from '../assets/images/linkedin.png'
import instagram from '../assets/images/instagram.png'
import gitlab from '../assets/images/gitlab.png'
import mailDarkMode from '../assets/images/mail_dark.png'
import locationDarkMode from '../assets/images/location_dark.png'
import linkedinDarkMode from '../assets/images/linkedin_dark.png'
import instagramDarkMode from '../assets/images/instagram_dark.png'
import gitlabDarkMode from '../assets/images/gitlab_dark.png'
import { FiArrowUpCircle } from 'react-icons/fi';
import { HashLink as Link } from 'react-router-hash-link'


export default function Contact(props) {
    const { darkMode, 
        titleColor, 
        isTitle, 
        isFontFamily, 
        fontStyle, 
        isBackground, 
        colorBackground,
        isDescription,
        descriptionColor } = props

    
const handleSubmit=(e)=> {
    e.preventDefault()
    const nameInput = e.target.elements.name
    const emailInput = e.target.elements.email
    const messageInput = e.target.elements.message

    const data = { 
        name: nameInput.value, 
        email: emailInput.value, 
        message: messageInput.value, 
    }

    axios.post(`${URL}/emails/send_email`, data)
		.then((response) => {
			nameInput.value = ""
			emailInput.value = ""
            messageInput.value = ""
			alert("Your message has been sent, thanks!")
		})
		.catch(function (error) {
			console.log(error);
		})
}

    return (
        <div className="contact-container shadow page-title" id="contact" style={{backgroundColor: isBackground && colorBackground}}>
            <div className="grid-2-contact">
                 <div>
                    <h1 className="title-contact gray" style={{color: isTitle? titleColor : darkMode? "#f2C080": "#777777",
                        fontFamily: isFontFamily && fontStyle}}>CONTACT</h1>
                </div>
                <div className="grid-2-contact-form">
                    <div className="social-media-container">
                        <h1 style={{color: isTitle && titleColor}}>GET IN TOUCH</h1>
                        {darkMode? 
                        <div className="get-in-touch">
                        <a target="_blank" href="https://gitlab.com/gerviebar" rel="noreferrer"><img src={gitlabDarkMode} alt="gitlab icon" className="social-media-icon"/></a>
                        <a target="_blank" href="https://www.linkedin.com/in/gervie-barczyk/" rel="noreferrer"><img src={linkedinDarkMode} alt="linkedin icon" className="social-media-icon"/></a>
                        <a target="_blank" href="https://www.instagram.com/gervie.bar/" rel="noreferrer"><img src={instagramDarkMode} alt="instagram icon" className="social-media-icon"/></a>
                        </div>: <div className="get-in-touch">
                        <a target="_blank" href="https://gitlab.com/gerviebar" rel="noreferrer"><img src={gitlab} alt="gitlab icon" className="social-media-icon"/></a>
                        <a target="_blank" href="https://www.linkedin.com/in/gervie-barczyk/" rel="noreferrer"><img src={linkedin} alt="linkedin icon" className="social-media-icon"/></a>
                        <a target="_blank" href="https://www.instagram.com/gervie.bar/" rel="noreferrer"><img src={instagram} alt="instagram icon" className="social-media-icon"/></a>
                        </div> }
                        <div className="contact-details-container">
                            <div className="wrapper-1">
                                {darkMode? 
                                <a href="mailto:someone@yoursite.com"><img src={mailDarkMode} alt="mail icon" /></a>:
                                <a href="mailto:someone@yoursite.com"><img src={mail} alt="mail icon" /></a>}  
                                <p className="email" style={{ color: isDescription && descriptionColor }}>
                                    GERVIEBARCZYK@GMAIL.COM
                                </p> 
                            </div>
                            <div className="wrapper-2">
                                {darkMode? 
                                <img src={locationDarkMode} alt="location icon" />:
                                <img src={location} alt="location icon" />}
                                <p className="location" style={{ color: isDescription && descriptionColor }}>
                                    WARSAW, POLAND
                                </p>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    <div className="form-div">
                        <form className="form-container" onSubmit={handleSubmit}>
                            <input required={true} type="text" name="name" placeholder="Your Name" 
                                style={{fontFamily: isFontFamily && fontStyle}}/>
                            <input required={true} type="email" name="email" placeholder="Your Email"
                                style={{fontFamily: isFontFamily && fontStyle}}/>
                            <textarea required={true} type="text" name="message" placeholder="Your Message" 
                                style={{fontFamily: isFontFamily && fontStyle}}/>
                            <button className="pushable" type="submit"><span className="front" style={{fontFamily: isFontFamily && fontStyle}}>SEND MESSAGE</span></button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="arrow-up-container">
                <Link smooth to="#home"><FiArrowUpCircle className="arrow-up-icon" /></Link>
            </div>
        </div>
    )
}
