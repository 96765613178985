import React from 'react';
import tick from '../assets/images/tick.png';
import { skillList } from '../_static/skills';

export default function Skills(props) {
  const {
    darkMode,
    titleColor,
    isTitle,
    isBackground,
    colorBackground,
    isFontFamily,
    fontStyle,
    isDescription,
    descriptionColor,
  } = props;

  return (
    <div
      className='skills-container grid-2-skills shadow page-title'
      id='skills'
      style={{ backgroundColor: isBackground && colorBackground }}
    >
      <div className='title-container'>
        <h1
          className='title-skills white'
          style={{
            color: isTitle ? titleColor : darkMode ? '#f2C080' : '#777',
            fontFamily: isFontFamily && fontStyle,
          }}
        >
          SKILLS
        </h1>
      </div>
      <div className='skills-list'>
        <p
          className='skills-text'
          style={{ color: isDescription && descriptionColor }}
        >
          Here are the <strong>tools</strong> and <strong>technologies</strong>{' '}
          I love to work with
        </p>
        <div className='skills-wrapper'>
          {skillList.map((skill) => (
            <h4 key={skill} className='skill-item'>
              {skill}
            </h4>
          ))}
        </div>

        {/* <div className='grid-3'>
          <div className='box-1 boxes'>
            <h4 style={{ color: isTitle && titleColor }}>Frontend</h4>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>
                JavaScript
              </p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>React</p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>
                React Native
              </p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>HTML</p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>CSS</p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>
                Material UI
              </p>
            </div>
          </div>
          <div className='box-2 boxes'>
            <h4 style={{ color: isTitle && titleColor }}>Backend</h4>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>
                Express
              </p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>
                MongoDB
              </p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>Node</p>
            </div>
          </div>
          <div className='box-2 boxes'>
            <h4 style={{ color: isTitle && titleColor }}>Others</h4>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>Git </p>
            </div>
            <div className='row-2'>
              <img src={tick} alt='tick icon' />
              <p style={{ color: isDescription && descriptionColor }}>
                Postman
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <br />
    </div>
  );
}
