import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import menuLight from '../assets/images/menu-light.png';
import menuDark from '../assets/images/menu-dark.png';
import { VscSymbolColor } from 'react-icons/vsc';
import smoothscroll from 'smoothscroll-polyfill';
import CloseIcon from '@material-ui/icons/Close';

export default function Navbar(props) {
  const [showLinks, setShowLinks] = useState(false);
  const [isStyle, setIsStyle] = useState(false);
  const {
    setDarkMode,
    darkMode,
    setColorBackground,
    setTitleColor,
    setIsBackground,
    setIsTitle,
    isTitle,
    titleColor,
    isBackground,
    colorBackground,
    setFontStyle,
    setIsFontFamily,
    isFontFamily,
    fontStyle,
    descriptionColor,
    setDescriptionColor,
    isDescription,
    setIsDescription,
  } = props;

  smoothscroll.polyfill();

  const showStyle = () => {
    setIsStyle(isStyle ? false : true);
  };

  const handleBackground = (color) => {
    setIsBackground(true);
    setColorBackground(color);
  };

  const handleTitle = (color) => {
    setIsTitle(true);
    setTitleColor(color);
  };

  const handleDescription = (color) => {
    setIsDescription(true);
    setDescriptionColor(color);
  };

  const handleFont = (font) => {
    setIsFontFamily(true);
    setFontStyle(font);
  };

  const handleClose = () => {
    setIsStyle(false);
  };

  const handleReset = () => {
    !darkMode && setDarkMode(true);
    setIsBackground(false);
    setIsTitle(false);
    setIsFontFamily(false);
    setIsDescription(false);
  };

  return (
    <div
      className='nav-bar-container'
      style={{ background: isBackground && colorBackground }}
    >
      <div className='toggle-container'>
        <span style={{ color: darkMode ? 'grey' : 'yellow' }}>☀</span>
        <span className='toggle'>
          <input
            checked={darkMode}
            onChange={() => {
              setDarkMode((prevMode) => !darkMode);
              setIsBackground(false);
              setIsTitle(false);
              setIsFontFamily(false);
              setIsDescription(false);
            }}
            type='checkbox'
            className='checkbox'
            id='checkbox'
          />
          <label htmlFor='checkbox' />
        </span>
        <span style={{ color: darkMode ? '#6a5acd' : 'grey' }}>☾</span>
      </div>
      <div
        className='nav-bar'
        id={showLinks ? 'hidden' : ''}
        style={{ background: isBackground && colorBackground }}
      >
        {showLinks ? (
          <>
            <Link smooth to='#about' onClick={() => setShowLinks(!showLinks)}>
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                ABOUT
              </p>
            </Link>
            <Link
              smooth
              to='#projects'
              onClick={() => setShowLinks(!showLinks)}
            >
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                PROJECTS
              </p>
            </Link>
            <Link smooth to='#skills' onClick={() => setShowLinks(!showLinks)}>
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                SKILLS
              </p>
            </Link>
            <Link smooth to='#contact' onClick={() => setShowLinks(!showLinks)}>
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                CONTACT
              </p>
            </Link>
          </>
        ) : (
          <>
            <Link smooth to='#about'>
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                ABOUT
              </p>
            </Link>
            <Link smooth to='#projects'>
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                PROJECTS
              </p>
            </Link>
            <Link smooth to='#skills'>
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                SKILLS
              </p>
            </Link>
            <Link smooth to='#contact'>
              <p
                className='nav-bar-menu'
                style={{
                  color: isTitle
                    ? titleColor
                    : darkMode
                    ? '#f2C080'
                    : '#777777',
                  fontFamily: isFontFamily && fontStyle,
                }}
              >
                CONTACT
              </p>
            </Link>
          </>
        )}
      </div>
      {darkMode ? (
        <>
          {showLinks ? (
            <span
              className='hamburger-icon'
              onClick={() => setShowLinks(!showLinks)}
            >
              <CloseIcon />
            </span>
          ) : (
            <img
              style={{ color: darkMode ? '#6a5acd' : 'grey' }}
              className='hamburger-icon'
              onClick={() => setShowLinks(!showLinks)}
              src={menuDark}
              alt='menu icon'
            />
          )}
        </>
      ) : (
        <>
          {showLinks ? (
            <span
              className='hamburger-icon'
              onClick={() => setShowLinks(!showLinks)}
            >
              <CloseIcon />
            </span>
          ) : (
            <img
              style={{ color: darkMode ? '#6a5acd' : 'grey' }}
              className='hamburger-icon'
              onClick={() => setShowLinks(!showLinks)}
              src={menuLight}
              alt='menu icon'
            />
          )}
        </>
      )}

      <span
        className='style-me'
        onClick={showStyle}
        style={{
          backgroundColor: isBackground && colorBackground,
          color: isDescription && descriptionColor,
          fontFamily: isFontFamily && fontStyle,
          borderColor: darkMode,
        }}
      >
        <VscSymbolColor /> style me...
      </span>
      <div
        className={isStyle ? 'style-list-active' : 'style-list-hidden'}
        id='style-me-box'
      >
        <div className='style-me-actions'>
          <div onClick={handleClose} className='close-button'>
            <CloseIcon />
          </div>
          <p className='reset-button' onClick={handleReset}>
            Reset
          </p>
        </div>
        <div className='color-container'>
          <p>Background</p>
          <div className='color-list'>
            <span
              className='color-1 size'
              onClick={() => handleBackground('#6e5773')}
            ></span>
            <span
              className='color-2 size'
              onClick={() => handleBackground('#8cba51')}
            ></span>
            <span
              className='color-3 size'
              onClick={() => handleBackground('#cceabb')}
            ></span>
            <span
              className='color-4 size'
              onClick={() => handleBackground('#440a67')}
            ></span>
            <span
              className='color-5 size'
              onClick={() => handleBackground('#9e9d89')}
            ></span>
            <span
              className='color-6 size'
              onClick={() => handleBackground('#5f939a')}
            ></span>
            <span
              className='color-7 size'
              onClick={() => handleBackground('#59886b')}
            ></span>
            <span
              className='color-8 size'
              onClick={() => handleBackground('#a3d2ca')}
            ></span>
          </div>
        </div>
        <div className='color-container'>
          <p>Title</p>
          <div className='color-list'>
            <span
              className='title-1 size'
              onClick={() => handleTitle('#31326f')}
            ></span>
            <span
              className='title-2 size'
              onClick={() => handleTitle('#96bb7c')}
            ></span>
            <span
              className='title-3 size'
              onClick={() => handleTitle('#fa7d09')}
            ></span>
            <span
              className='title-4 size'
              onClick={() => handleTitle('#e84545')}
            ></span>
            <span
              className='title-5 size'
              onClick={() => handleTitle('#151515')}
            ></span>
            <span
              className='title-6 size'
              onClick={() => handleTitle('#b4aee8')}
            ></span>
            <span
              className='title-7 size'
              onClick={() => handleTitle('#654062')}
            ></span>
            <span
              className='title-8 size'
              onClick={() => handleTitle('#D6ED17FF')}
            ></span>
          </div>
        </div>
        <div className='color-container'>
          <p>Description</p>
          <div className='color-list'>
            <span
              className='description-1 size'
              onClick={() => handleDescription('#55b3b1')}
            ></span>
            <span
              className='description-2 size'
              onClick={() => handleDescription('#a8ff3e')}
            ></span>
            <span
              className='description-3 size'
              onClick={() => handleDescription('#120078')}
            ></span>
            <span
              className='description-4 size'
              onClick={() => handleDescription('#e84545')}
            ></span>
            <span
              className='description-5 size'
              onClick={() => handleDescription('#151515')}
            ></span>
            <span
              className='description-6 size'
              onClick={() => handleDescription('#b4aee8')}
            ></span>
            <span
              className='description-7 size'
              onClick={() => handleDescription('#f6830f')}
            ></span>
            <span
              className='description-8 size'
              onClick={() => handleDescription('#ffd800')}
            ></span>
          </div>
        </div>
        <div className='color-container'>
          <p>Font-family</p>
          <div className='color-list'>
            <span
              className='font-1 size black'
              onClick={() => handleFont("'Poor Story', cursive")}
            >
              G
            </span>
            <span
              className='font-2 size black'
              onClick={() => handleFont("'Bubblegum Sans', cursive")}
            >
              G
            </span>
            <span
              className='font-3 size black'
              onClick={() => handleFont("'Old Standard TT', serif")}
            >
              G
            </span>
            <span
              className='font-4 size black'
              onClick={() => handleFont("'Electrolize', sans-serif")}
            >
              G
            </span>
            <span
              className='font-5 size black'
              onClick={() => handleFont("'Pathway Gothic One', sans-serif")}
            >
              G
            </span>
            <span
              className='font-6 size black'
              onClick={() => handleFont("'Cormorant', serif")}
            >
              G
            </span>
            <span
              className='font-7 size black'
              onClick={() => handleFont("'Orelega One', cursive")}
            >
              G
            </span>
            <span
              className='font-8 size black'
              onClick={() => handleFont("'Kosugi Maru', sans-serif")}
            >
              G
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
