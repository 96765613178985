import React from 'react';
import MyTodo from '../assets/images/my-todo-app.png';
import Ecommerce from '../assets/images/ecommerce-app.png';
import MarbleCraftCo from '../assets/images/marblecraftco-app.png';
import WeatherApp from '../assets/images/weather-app-1.png';
import Moviee from '../assets/images/moviee.png';
import BCSClone from '../assets/images/bcs-app.png';
import { SiGitlab } from 'react-icons/si';
import { AiOutlineGlobal } from 'react-icons/ai';

export default function Projects(props) {
  const {
    darkMode,
    titleColor,
    isTitle,
    isFontFamily,
    fontStyle,
    isBackground,
    colorBackground,
    isDescription,
    descriptionColor,
  } = props;

  return (
    <div
      className='projects-container grid-2-project shadow page-title'
      id='projects'
      style={{ backgroundColor: isBackground && colorBackground }}
    >
      <div className='flex-project'>
        <h1
          className='title-projects gray'
          style={{
            color: isTitle ? titleColor : darkMode ? '#f2C080' : '#777777',
            fontFamily: isFontFamily && fontStyle,
          }}
        >
          PROJECTS
        </h1>
      </div>
      <div className='projects-sub-container'>
        {/* <p className="projects-text" style={{ color: isTitle && titleColor }}>
                    Here are the projects I made at <strong>Barcelona Code School</strong>
                </p> */}
        <div className='row reversed'>
          <div>
            <img className='img-project' src={Ecommerce} alt='ecommerce app' />
            <br />
          </div>
          <div>
            <h2 style={{ color: isTitle && titleColor }}>CHEEKY-CHIC</h2>
            <p style={{ color: isDescription && descriptionColor }}>
              CheekyChic is an ecommerce web application consists of two
              separate features — the storefront through which enables the
              customers to browse the company's range of products and make
              purchases, and the administrative panel which is used to track
              orders, create, delete and update unlimited products with ease.
            </p>
            <p
              className='built-with-text'
              style={{ color: isTitle && titleColor }}
            >
              Built with:{' '}
              <span
                className='tech-used'
                style={{ color: isDescription && descriptionColor }}
              >
                React, React Hooks, Express, Axios, CSS Flexbox, CSS Grid,
                Stripe, NodeMailer
              </span>
            </p>
            <div className='flex-code'>
              <div>
                <SiGitlab />
                <a
                  target='_blank'
                  href='https://gitlab.com/gerviebar/ecommerce-project'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Code
                </a>
              </div>
              {/* <div>
                <AiOutlineGlobal />
                <a
                  target='_blank'
                  href='http://cheekychic.store'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Live
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className='row'>
          <div>
            <h2 style={{ color: isTitle && titleColor }}>MOVIEE</h2>
            <p style={{ color: isDescription && descriptionColor }}>
              Moviee is a React application where users can search and find
              information on their next movie to watch. This includes reviews,
              ratings, and a quick overview of a movie (release date, director,
              cast, etc). Users can restrict the search further by providing the
              movie genre, movie title or just get recommendations by browsing
              through the latest movies, popular movies, top-rated movies as
              well as upcoming releases. There is also an "add to watchlist"
              option where users can create an account and keep track of movies
              by adding and removing them from the watchlist.
            </p>
            <p
              className='built-with-text'
              style={{ color: isTitle && titleColor }}
            >
              Built with:
              <span
                className='tech-used'
                style={{ color: isDescription && descriptionColor }}
              >
                {' '}
                React.js, CSS, Express, Node.js, MongoDB, Jest, Enzyme
              </span>
            </p>
            <div className='flex-code'>
              <div>
                <SiGitlab />
                <a
                  target='_blank'
                  href='https://gitlab.com/gerviebar/moviee'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Code
                </a>
              </div>
              {/* <div>
                <AiOutlineGlobal />
                <a
                  target='_blank'
                  href='http://164.90.171.6/'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Live
                </a>
              </div> */}
            </div>
          </div>
          <div>
            <img className='img-project' src={Moviee} alt='movie search' />
            <br />
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className='row reversed'>
          <div>
            <img
              className='img-project'
              src={MarbleCraftCo}
              alt='marble craft co app'
            />
            <br />
          </div>
          <div>
            <h2 style={{ color: isTitle && titleColor }}>
              MARBLE CRAFT CO. (in progress)
            </h2>
            <p style={{ color: isDescription && descriptionColor }}>
              Marble Craft Co. is a sleek and modern e-commerce platform
              designed to showcase and sell premium marble products. Built using
              Next.js, this high-performance app offers users a seamless online
              shopping experience, from product browsing to checkout. The app
              focuses on providing a visually appealing interface, and
              fast-loading experience.
            </p>
            <p
              className='built-with-text'
              style={{ color: isTitle && titleColor }}
            >
              Built with:{' '}
              <span
                className='tech-used'
                style={{ color: isDescription && descriptionColor }}
              >
                HTML, CSS, JavaScript, TypeScript, React, Next.js, Redux, Styled
                Components, Resend
              </span>
            </p>
            <div className='flex-code'>
              <div>
                <SiGitlab />
                <a
                  target='_blank'
                  href='hhttps://github.com/gervieb/marble-craft-co'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Code
                </a>
              </div>
              {/* <div>
                <AiOutlineGlobal />
                <a
                  target='_blank'
                  href='http://cheekychic.store'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Live
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className='row'>
          <div>
            <h2 style={{ color: isTitle && titleColor }}>MY TODO</h2>
            <p style={{ color: isDescription && descriptionColor }}>
              My-Todo is a simple application to organize everyday tasks. It
              allows users to add a to-do, remove a to-do, toggle the completed
              status of a to-do.
            </p>
            <p
              className='built-with-text'
              style={{ color: isTitle && titleColor }}
            >
              Built with:
              <span
                className='tech-used'
                style={{ color: isDescription && descriptionColor }}
              >
                {' '}
                HTML, CSS, JavaScript, React.js
              </span>
            </p>
            <div className='flex-code'>
              <div>
                <SiGitlab />
                <a
                  target='_blank'
                  href='https://gitlab.com/gerviebar/my-todo'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Code
                </a>
              </div>
              <div>
                <AiOutlineGlobal />
                <a
                  target='_blank'
                  href='http://mytodo-project.surge.sh/'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Live
                </a>
              </div>
            </div>
          </div>
          <div>
            <img className='img-project' src={MyTodo} alt='todo app' />
            <br />
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className='row reversed'>
          <div>
            <img
              className='img-project-mobile'
              src={WeatherApp}
              alt='weather app'
            />
            <br />
          </div>
          <div>
            <h2 style={{ color: isTitle && titleColor }}>WEATHER APP</h2>
            <p style={{ color: isDescription && descriptionColor }}>
              Weather app provides users with an up-to-date weather conditions
              based on their actual location. Users can also obtain a weather
              forecast for any city in the world with autocomplete feature as
              well as a 6-day forecast using OpenWeatherMap API and Geolocation
              API.
            </p>
            <p
              className='built-with-text'
              style={{ color: isTitle && titleColor }}
            >
              Built with:
              <span
                className='tech-used'
                style={{ color: isDescription && descriptionColor }}
              >
                {' '}
                HTML, CSS, JavaScript, React.js, React Native
              </span>
            </p>
            <div className='flex-code'>
              <div>
                <SiGitlab />
                <a
                  target='_blank'
                  href='https://gitlab.com/gerviebar/weather-rn-app'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Code
                </a>
              </div>
              {/* <div>
                <AiOutlineGlobal />
                <a
                  target='_blank'
                  href='http://gbarczyk-weather-demo.surge.sh'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Live
                </a>
              </div> */}
            </div>
          </div>
        </div>
        {/* <br />
        <hr />
        <br /> */}

        {/* <div className='row reversed'>
          <div className='project-image-wrapper'>
            <img
              className='img-project'
              src={BCSClone}
              alt='barcelona code school clone page'
            />
            <br />
          </div>
          <div>
            <h2 style={{ color: isTitle && titleColor }}>
              BARCELONA CODE SCHOOL CLONE PAGE
            </h2>
            <p style={{ color: isDescription && descriptionColor }}>
              It is a clone homepage of Barcelona Code School's website without
              any functionality.
            </p>
            <p
              className='built-with-text'
              style={{ color: isTitle && titleColor }}
            >
              Built with:
              <span
                className='tech-used'
                style={{ color: isDescription && descriptionColor }}
              >
                {' '}
                HTML, CSS{' '}
              </span>
            </p>
            <div className='flex-code'>
              <div>
                <SiGitlab />
                <a
                  target='_blank'
                  href='https://gitlab.com/gerviebar/02_homepage_bcs'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Code
                </a>
              </div>
              <div>
                <AiOutlineGlobal />
                <a
                  target='_blank'
                  href='https://gerviebar.gitlab.io/02_homepage_bcs/'
                  rel='noreferrer'
                  style={{
                    color: isTitle ? titleColor : darkMode ? '#f2C080' : '#000',
                  }}
                >
                  View Live
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
