import React from 'react'
import { AiOutlineCopyright } from 'react-icons/ai';
export default function Footer(props) {
    const { 
        isBackground, 
        colorBackground, 
        darkMode, 
        isFontFamily, 
        fontStyle,
        isDescription,
        descriptionColor} = props

        
    return (
        <footer className="shadow" style={{ background: isBackground? colorBackground: darkMode? "#1a1919" : "#777"}}>
            <p style={{ fontFamily: isFontFamily && fontStyle, color: isDescription && descriptionColor }}>
                <AiOutlineCopyright/> 
                2021 GERVIE BARCZYK. All rights reserved.
            </p>
        </footer>
    )
}

