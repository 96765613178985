import React from 'react';
import darkLogo from '../assets/images/logo-dark.png';
import lightLogo from '../assets/images/logo-light.png';

export default function Avatar({ isBackground, colorBackground, darkMode }) {
  const logo = darkMode ? darkLogo : lightLogo;
  return (
    <div
      className='avatar-container'
      style={{ background: isBackground && colorBackground }}
    >
      <img className='avatar' src={logo} alt='logo' />
    </div>
  );
}
