import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Avatar from './components/Avatar';
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Skills from './components/Skills';
import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(getInitialMode());
  const [colorBackground, setColorBackground] = useState('');
  const [titleColor, setTitleColor] = useState('');
  const [descriptionColor, setDescriptionColor] = useState('');
  const [fontStyle, setFontStyle] = useState('');
  const [isBackground, setIsBackground] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isFontFamily, setIsFontFamily] = useState(false);

  useEffect(() => {
    localStorage.setItem('dark', JSON.stringify(darkMode));
  }, [darkMode]);

  function getInitialMode() {
    const isReturningVisitor = 'dark' in localStorage;
    const savedMode = JSON.parse(localStorage.getItem('dark'));
    const userPrefersDark = getPreferColorScheme();

    if (isReturningVisitor) {
      return savedMode;
    } else if (userPrefersDark) {
      return true;
    } else {
      return false;
    }
  }

  function getPreferColorScheme() {
    if (!window.matchMedia) return;
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  return (
    <div
      className={darkMode ? 'dark-mode' : 'light-mode'}
      style={{ fontFamily: fontStyle }}
    >
      <Router>
        <Avatar
          isBackground={isBackground}
          colorBackground={colorBackground}
          darkMode={darkMode}
        />

        <Navbar
          setDarkMode={setDarkMode}
          darkMode={darkMode}
          setColorBackground={setColorBackground}
          setIsBackground={setIsBackground}
          titleColor={titleColor}
          setTitleColor={setTitleColor}
          isTitle={isTitle}
          setIsTitle={setIsTitle}
          isBackground={isBackground}
          colorBackground={colorBackground}
          setIsFontFamily={setIsFontFamily}
          setFontStyle={setFontStyle}
          isFontFamily={isFontFamily}
          fontStyle={fontStyle}
          isDescription={isDescription}
          setIsDescription={setIsDescription}
          descriptionColor={descriptionColor}
          setDescriptionColor={setDescriptionColor}
        />

        <Header
          isTitle={isTitle}
          darkMode={darkMode}
          titleColor={titleColor}
          isBackground={isBackground}
          colorBackground={colorBackground}
          isFontFamily={isFontFamily}
          fontStyle={fontStyle}
          isDescription={isDescription}
          descriptionColor={descriptionColor}
        />

        <Route
          exact
          to='./about'
          render={(props) => (
            <AboutMe
              {...props}
              darkMode={darkMode}
              titleColor={titleColor}
              isTitle={isTitle}
              isFontFamily={isFontFamily}
              fontStyle={fontStyle}
              isBackground={isBackground}
              colorBackground={colorBackground}
              isDescription={isDescription}
              descriptionColor={descriptionColor}
            />
          )}
        />

        <Route
          exact
          to='./projects'
          render={(props) => (
            <Projects
              {...props}
              darkMode={darkMode}
              titleColor={titleColor}
              isTitle={isTitle}
              isFontFamily={isFontFamily}
              fontStyle={fontStyle}
              isBackground={isBackground}
              colorBackground={colorBackground}
              isDescription={isDescription}
              descriptionColor={descriptionColor}
            />
          )}
        />

        <Route
          exact
          to='./skills'
          render={(props) => (
            <Skills
              {...props}
              darkMode={darkMode}
              titleColor={titleColor}
              isTitle={isTitle}
              isFontFamily={isFontFamily}
              fontStyle={fontStyle}
              isBackground={isBackground}
              colorBackground={colorBackground}
              isDescription={isDescription}
              descriptionColor={descriptionColor}
            />
          )}
        />

        <Route
          exact
          to='./contact'
          render={(props) => (
            <Contact
              {...props}
              darkMode={darkMode}
              titleColor={titleColor}
              isTitle={isTitle}
              isFontFamily={isFontFamily}
              fontStyle={fontStyle}
              isBackground={isBackground}
              colorBackground={colorBackground}
              isDescription={isDescription}
              descriptionColor={descriptionColor}
            />
          )}
        />

        <Footer
          isBackground={isBackground}
          colorBackground={colorBackground}
          darkMode={darkMode}
          titleColor={titleColor}
          isTitle={isTitle}
          isFontFamily={isFontFamily}
          fontStyle={fontStyle}
          isDescription={isDescription}
          descriptionColor={descriptionColor}
        />
      </Router>
    </div>
  );
}

export default App;
