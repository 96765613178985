export const skillList = [
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React.js',
  'React Native',
  'Next.js',
  'Redux',
  'Vue.js',
  'Sass',
  'Tailwind',
  'Bootstrap',
  'Styled Components',
  'Storybook',
  'Node.js',
  'Express',
  'GraphQL',
  'PostgreSQL',
  'MongoDB',
  'Supabase',
  'Python',
  'Jest',
  'Enzyme',
  'Cypress',
  'Git',
  'Docker',
  'Kubernetes',
  'AWS',
  'Azure',
];
